import React, { useEffect, useState, useContext } from 'react'
import Axios from '../config/axios'
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../App.css'
import { LoginContext } from '../context/login.context'

const Welcome = () => {
    // const [userData, setUserData] = useState([])
    const { HandleLogout } = useContext(LoginContext)
    const websiteId = "website3"
    const [name, setName] = useState("")
    const navigate = useNavigate()
    const [virtualId, setVirtualId] = useState("")
    const userId = Cookies.get("uid")
    const UserActions = async () => {
        await Axios.post("/api/v3/session/action", {
            browserId: Cookies.get("browserId")
        })
    }



    
    const isAuthenticated = () => {

        let authorized = userId ? true : false
        if (authorized) {
            return navigate("/welcome")
        }
        return navigate("/")
    }

    const GetUserDetails = async () => {
        console.log("Getting User Details")
        if (userId) {
            await Axios.get("/api/v1/number/" + userId)
            .then(res => Cookies.set("phone", res.data.phone))
            .catch(err => console.error(err))

            // Which app scanned in.
            await Axios.get("/api/" + userId)
            .then(res => {
                setName(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.name)
                setVirtualId(res.data.associated_number[Cookies.get("phone")].connected_apps.sneak.connected_accounts[websiteId].virtualid)                
            })
            .catch(err => console.error(err))
        }
    }

    useEffect(() => {
        GetUserDetails()

        let interval = setInterval(UserActions , 10000)
        isAuthenticated()

        return () => {
            clearInterval(interval)
        }
    }, [])

    return (
        <div className='welcome-page'>
            { name ?
            <div>
                <h1>Welcome <span className='name-field'>{name}</span></h1> 
                <h3 className='viid'>@{virtualId}</h3>
                <button className='button-61' onClick={HandleLogout}>Logout</button>
            </div>
            : ""}
        </div>
    )
}

export default Welcome