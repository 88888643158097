import React, { createContext } from "react";
import Axios from '../config/axios';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
const LoginContext = createContext()


const LoginProvider = ({ children }) => {
    const navigate = useNavigate()

    const HandleLogout = async () => {
        const cookies = Cookies.get();
        const userId = Cookies.get("uid")
        const phone = Cookies.get("phone")
        const browserId = Cookies.get("browserId")

        // const response = await Axios.delete("/api/v2/session/remove", {
        //     browser_id: browserId,
        //     phone: phone,
        //     uid: userId,
        //     account: "website3",
        // })
        await Axios.post("/api/v1/account/status", {
            phone: phone,
            uid: userId,
            account: "website3",
            app: "sneak",
            session_status: false
        })
        .then((response => console.log(response.data)))
        .catch((err) => console.error(err))


        for (const cookie in cookies) {
            Cookies.remove(cookie);
        }

        // Delete the session from the server too
        console.log('All cookies cleared.');

        window.location.reload();
        return navigate("/")
    }

    return (
        <LoginContext.Provider value={{ HandleLogout }}>
            {children}
        </LoginContext.Provider>
    )
}

export { LoginContext, LoginProvider}