import { Routes, Route } from 'react-router-dom'
import { Home, Welcome } from './pages';
// import { } from 'react-router-dom'
import Header from './components/header.components';
import './App.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <div className="bg-shape"></div>
      <Routes>
        <Route path='/' element={<Home />}  />
        <Route path='/welcome' element={<Welcome/>} />
      </Routes>
    </div>
  );
}

export default App;