import React from 'react'
import '../App.css';

const Header = () => {
  return (
    <div className='header'>
        <div className="left-headear">
            <h1>Website 3</h1>
        </div>
        <div className="right-header"></div>
    </div>
  )
}

export default Header;