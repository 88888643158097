import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import '../App.css';
import Axios from '../config/axios';
import Logo from '../assets/logo.png';
import QrLOGO from '../assets/qrcode.png';
import {QRCode} from 'react-qrcode-logo';
// import { io } from 'socket.io-client';
import Loader from '../loader.svg';

import Cookies from 'js-cookie';


const Home = () => {
  
  const navigate = useNavigate()
  const [ipAddress, setIpAddress] = useState("")
  const [token, setToken] = useState("")
  const [browserId, setBrowserId] = useState()
  const [isCopied, setIsCopied] = useState(false);
  const textRef = useRef(null);


  const RequestForBrowserId = async () => {
    // await getIpAddress()
    if (Cookies.get("browserId") === undefined) {
      const response = await Axios.post("/api/v3/browserId", {
        ip: "192.168.1.1",
        websiteid: "website3"
      })

      console.log(response.data)
      Cookies.set("browserId", response.data.browserId)
      setBrowserId(response.data.browserId)
    }
    console.log(Cookies.get("browserId"))
    setBrowserId(Cookies.get("browserId"))
  }

  const getIpAddress = async () => {
    const response = await Axios.get("https://api.ipify.org/?format=json")
    console.log(response.data.ip)
    setIpAddress(response.data.ip)
  }

  const GenerateBrowserToken = async () => {
    if (browserId !== "") {
      const response = await Axios.post("/api/v3/gen/token", {
        "browserId": browserId,
        "ip": ipAddress ? ipAddress : "192.168.1.1",
        "website": "website3"
      })
      console.log(response.data.token)
      if (response.data.token !== undefined) {
        setToken(response.data.token)
      }
    }
    
  }


  const RestfulConnection = async () => {
    if (token) {
      const response = await Axios.get("/api/v2/session/" + browserId)
      if (response.data.session_status) {
        Cookies.set("uid", response.data.uid)
        return navigate("/welcome")
      }
      return null
    }
  }

  const RetreiveFreshToken = async () => {
    if (browserId !== "") {
      const response = await Axios.post("/api/v3/latest/details", {
        "browserId": browserId,
        "ip": ipAddress ? ipAddress : "192.168.1.1",
        "website": "website3"
      })
      if (response.data.token !== undefined) {
        setToken(response.data.token)
      }
    }
  }
  setInterval(async () => {
    console.log('Fetching Fresh Token.')
    await RetreiveFreshToken()
  }, 10000)

  setInterval(async() => {
    await RestfulConnection()
  }, 10000)


  const Loading = () => {
    return (
      <div className='loader-container'>
        <img className='loader-img' src={Loader} alt="" />
      </div>
    )
  }

  useEffect(() => {
    console.log("BASE_URL", process.env.REACT_APP_BASE_URL)
    // Request of Ip Address
    getIpAddress()
    // Get BrowserId 
    RequestForBrowserId()

    // Get Token with above browserId
    GenerateBrowserToken()

  }, [])

  const handleCopyClick = () => {
    const textElement = textRef.current;

    if (textElement) {
      const range = document.createRange();
      range.selectNode(textElement);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      setIsCopied(true)
      setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
  };
  return (
    <div className='home-page'>

      <div className='qr-container'>
        <div className='qr-variables'>
          <h1 className='instr-header'>Use Sneak App to Scan and login.</h1>
          <ol className='instructions'>
            <li>1. Open Sneak App on your phone.</li>
            <li>2. Tap on <img className='qr-icon-img' src={QrLOGO} alt="qr-icon" /> and Scan the Qrcode to login.</li>
          </ol>
        </div>
        <div className="qr-body">
          {token ?
            <div>
              <div className='qr-code-token'>
                <QRCode value={token} size={"250"} logoImage={Logo} logoHeight={"80"} logoWidth={"80"} bgColor='transparent'/>
              </div>
              <div className="qr-code-value" ref={textRef}>
                {token}
              </div>
              <button className='button-59' onClick={handleCopyClick}>
                {isCopied ? 'Copied!' : 'Click to copy'}
              </button>
            </div> :
            <Loading />
          }
        </div>
      </div>
    </div>
  )
}

export default Home